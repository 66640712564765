.CustomMarkdown {
    color: rgba(0, 0, 0, 0.87);
}
.CustomMarkdown ul,
.CustomMarkdown ul {
    padding-left: 16px;
    list-style: inherit;
    margin-bottom: 24px;
}

.CustomMarkdown p {
    margin-bottom: 12px;
}

.CustomMarkdown h1 {
    font-size: 18px;
    font-weight: bold;
}
.CustomMarkdown h2 {
    font-size: 15px;
    font-weight: bold;
}